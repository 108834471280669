module.exports = [{
      plugin: require('/Users/Nick/Sites/web-fun-gatsby/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/Nick/Sites/web-fun-gatsby/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1000,"showCaptions":true,"linkImagesToOriginal":false},
    },{
      plugin: require('/Users/Nick/Sites/web-fun-gatsby/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/Nick/Sites/web-fun-gatsby/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Fundamentals of Web Application Development","short_name":"Web App Fundamentals","start_url":"/","background_color":"#ffffff","theme_color":"#00539f","display":"minimal-ui","icon":"content/assets/web-fun-icon.png"},
    },{
      plugin: require('/Users/Nick/Sites/web-fun-gatsby/node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"3","matomoUrl":"https://m.nikulis.io","siteUrl":"https://web-fundamentals.dev"},
    },{
      plugin: require('/Users/Nick/Sites/web-fun-gatsby/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/Nick/Sites/web-fun-gatsby/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/Users/Nick/Sites/web-fun-gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
